<template>
  <div class="login">
    <img src="@/assets/img/guangfa/login-bg.png" />
    <form>
      <div class="loginflex">
        <label>操作员登录手机号：</label>
        <input placeholder="请输入手机号" v-model="submitForm.phone" />
      </div>
      <div class="loginflex loginyzm" style="padding-right: 0">
        <label>验证码：</label>
        <input placeholder="请输入验证码" v-model="submitForm.captcha" />
        <div :class="btn.disable?'getcode disabled':'getcode'" @click="getCodeMeds">{{btn.disable?`${btn.num}s`:'获取验证码'}}</div>
      </div>
      <div class="submit" @click="submitMeds">登录</div>
    </form>
    <div class="logintips">
      如有疑问请咨询客服电话<a href="tel:400-882-6000">400-882-6000</a>
    </div>
  </div>
</template>

<script>
import { reqGuangfaLogin, reqGuangfaSendCaptcha } from '@/api/guangfa-project'
export default {
  name: 'GuanfaLogin',
  data() {
    return {
      submitForm: {
        phone: undefined,
        captcha: undefined
      },
      btn:{
        disable:false,
        timer:null,
        num:60
      },
    }
  },
  created() {},
  methods: {
    async submitMeds() {
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.submitForm.phone)) {
        this.$toast.fail('请填写正确的手机号')
        return false
      }
      if (this.submitForm.captcha !== 0 && !this.submitForm.captcha) {
        this.$toast.fail('请填写验证码')
        return false
      }
      try {
        const { data } = await reqGuangfaLogin(this.submitForm)
        if (data) {
          localStorage.setItem('guangfaToken', data)
          this.$router.push({ name: 'GuanfaExchangeHome' })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getCodeMeds() {
      if (!this.btn.disable) {
        const reg = /^1[3456789]\d{9}$/
        if (this.submitForm.phone && reg.test(this.submitForm.phone)) {
          const data = await reqGuangfaSendCaptcha({
            phone: this.submitForm.phone,
            operatorTag: 1 // 操作员标识
          })
          if (data && data.code === 0) {
            this.$toast.success(data.message)
            this.btn.disable = true;
            this.btn.num = 60;
            this.btn.timer = setInterval(() =>{
              this.btn.num--
              if (this.btn.num <=0){
                this.btn.disable = false;
                this.btn.timer=null;
              }
            },1000)
          }else{
            this.btn.disable = false;
          }
        } else {
          this.btn.disable = false;
          this.$toast.fail('请填写正确的手机号')
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/guangfa.less';
</style>
